/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function () {
          return this.hostname && this.hostname !== location.hostname;
        }).attr('target', '_blank').attr('rel', 'noopener');

        // Navigation
        $('.js-toggle-nav').click(function (e) {
          e.preventDefault();

          $('body').toggleClass('nav-is-visible');
        });

        // Dropdown
        $('.js-toggle-dropdown').click(function (e) {
          e.preventDefault();

          $(this).parent().toggleClass('is-expanded').siblings().removeClass('is-expanded');
        });

        // Initiate lazy load
        //new LazyLoad();
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // Hero background
        var hero = $('.hero');
        var heroMobileBG = hero.data('mobile');
        var heroDesktopBG = hero.data('desktop');

        if (!$('body.single-properties').length) {
          if (window.matchMedia('(max-width: 1023px)').matches) {
            if (heroMobileBG) {
              hero.css('background-image', 'url(' + heroMobileBG + ')');
            }
          } else {
            if (heroDesktopBG) {
              hero.css('background-image', 'url(' + heroDesktopBG + ')');
            }
          }
        }

        // Testimonial background
        var testimonials = $('.section--testimonials');
        var testimonialMobileBG = testimonials.data('mobile');
        var testimonialDesktopBG = testimonials.data('desktop');

        if (window.matchMedia('(max-width: 1023px)').matches) {
          if (testimonialMobileBG) {
            testimonials.css('background-image', 'url(' + testimonialMobileBG + ')');
          }
        } else {
          if (testimonialDesktopBG) {
            testimonials.css('background-image', 'url(' + testimonialDesktopBG + ')');
          }
        }

        if ($('.c-carousel--test').length) {
          $('.c-carousel--test').slick({
            accessibility: true,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 15000,
            arrows: false,
            dots: true,
            fade: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
          });
        }

        // Responsive Video(s)
        var videos = $("iframe[src^='https://www.youtube.com'], iframe[src^='https://player.vimeo.com/']");
        var videoContainer = $('.js-responsive-video');

        videos.each(function () {
          $(this).data('aspectRatio', this.height / this.width).removeAttr('height').removeAttr('width');
        });

        $(window).resize(function () {
          var videoNewWidth = videoContainer.width();

          videos.each(function () {
            $(this).width(videoNewWidth).height(videoNewWidth * $(this).data('aspectRatio'));
          });
        }).resize();

        // Gravity Forms
        var gravityFormsError = $('.gform_validation_error');

        if (gravityFormsError.length) {
          $('body').addClass('gf-error');
        }

        if (window.matchMedia('(max-width: 1023px)').matches) {
          // Add a collapsed state to header and adjust body padding to account for changes made by header state
          $(window).scroll(function () {
            var currentScroll = $(this).scrollTop();

            if (currentScroll >= 10) {
              $('.header').addClass('is-collapsed');
              $('body').css('padding-top', 59); // height of header with logo container hidden
            } else {
              $('.header').removeClass('is-collapsed');
              $('body').css('padding-top', 170); // height of full header
            }
          });
        }
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        if (window.matchMedia('(max-width: 1023px)').matches) {
          // Add stuck state to header on mobile
          if ($('.page-template-home-custom').length) {
            $('.header').addClass('is-stuck');
          }
        }

        /*
        if (window.matchMedia('(min-width: 1024px)').matches) {
        var navHero = $('.nav--hero');
        var navHeroTop = navHero.offset().top;

        if (navHero.length) {
        $(window).scroll(function () {
        var currentScroll = $(this).scrollTop();

        if (currentScroll >= navHeroTop) {
        navHero.addClass('is-stuck');
      } else {
      navHero.removeClass('is-stuck');
    }
  });
}
}
*/
}
},
// Property
'single_properties': {
  init: function () {
    // JavaScript to be fired on the home page
  },
  finalize: function () {
    // JavaScript to be fired on the home page, after the init JS
    // Slick
    if ($('.js-carousel-gallery').length) {
      $('.js-carousel-gallery').slick({
        arrows: true,
        asNavFor: '.js-carousel-nav',
        dots: false,
        fade: true,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        rows: 0,
        slidesToShow: 1,
        slidesToScroll: 1
      });
    }

    if ($('.js-carousel-nav').length) {
      $('.js-carousel-nav').slick({
        arrows: false,
        asNavFor: '.js-carousel-gallery',
        autoplay: true,
        dots: false,
        focusOnSelect: true,
        rows: 0,
        slidesToShow: 3,
        slidesToScroll: 1
      });
    }
  }
}
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function (func, funcname, args) {
    var fire;
    var namespace = Sage;
    funcname = (funcname === undefined) ? 'init' : funcname;
    fire = func !== '';
    fire = fire && namespace[func];
    fire = fire && typeof namespace[func][funcname] === 'function';

    if (fire) {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function () {
    // Fire common init JS
    UTIL.fire('common');

    // Fire page-specific init JS, and then finalize JS
    $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
      UTIL.fire(classnm);
      UTIL.fire(classnm, 'finalize');
    });

    // Fire common finalize JS
    UTIL.fire('common', 'finalize');
  }
};

// Load Events
$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
